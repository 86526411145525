* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  --body-margin-left: 150px;
  --body-color: black;
}

html,
body,
#app {
  height: 100svh;
  overflow: hidden;
  background-color: rgb(190, 190, 190);
  color: var(--body-color);
}

#app {
  display: flex;
  justify-content: center;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 100%;
}

a {
  all: unset;
  transition: all 0.5s ease-in-out;
}

.canvas {
  max-width: 1920px;
  max-height: 1080px;
  margin: auto;
  box-shadow: 100px 100px 60px 0px rgba(0, 0, 0, 0.64);
}

@media (min-width: 2160px) {
  .canvas {
    zoom: 1;
    max-width: 1920px;
    max-height: 1080px;
  }

  .container {
    zoom: 0.5;
  }
}

nav {
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 45px;
  display: flex;
  padding: 0 16px;
  gap: 24px;
  z-index: 120;
}

nav button {
  height: 50%;
  padding: 12px 2px;
}

.logo {
  transform-origin: left center;
}

.mail-icon {
  transform-origin: right center;
}

a:hover {
  font-size: 2rem;
  cursor: pointer;
}

button {
  all: unset;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

button:hover {
  transform: scale(1.25);
}

button:active {
  transform: scale(0);
}

.close-button {
  position: absolute;
  top: 6rem;
  right: 6rem;
  max-width: 50px;
}

/* Typography */

h1 {
  text-wrap: balance;
  font-size: 5rem;
  font-weight: 600;
  line-height: 94.9%;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

h2 {
  margin-top: 1.5rem;
  text-wrap: balance;
  font-size: 2.4375rem;
  font-weight: 600;
  text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.adendum {
  position: relative;
  align-self: center;
  text-align: center;
  margin-top: 3rem;
  opacity: 0;
  animation: Reveal 1s forwards 4s;
}

.down-arrow {
  position: relative;
  animation: Float 1s ease 1s infinite both alternate;
}

.down-arrow path {
  stroke: var(--body-color);
}

.light {
  color: white;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.info svg {
  padding-top: 48px;
  max-height: 150px;
}

@media (max-width: 990px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 1rem;
  }
}

/* Layout */

.container {
  zoom: 0.75;
  display: flex;
  position: absolute;
  width: inherit;
  height: inherit;
  transform: none !important;
  align-items: center;
  /* This hides the containers off screen until they get opacity: 0, because otherwise they all appear at the start */
  top: -1500px;
}

.right {
  justify-content: flex-end;
}

.center {
  text-align: center;
  justify-content: center;
}

.intro {
  position: relative;
  margin: 0 var(--body-margin-left);
  width: 50%;
}

.modal {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

.not {
  animation: Hide 1s forwards;
}

.visible {
  display: flex;
  animation: Reveal 1s forwards;
}

.hidden {
  height: inherit;
  width: inherit;
  opacity: 0;
  animation: Reveal 1s forwards 2s;
}

.hidden > div {
  height: inherit;
  width: inherit;
}

/* Animations */

@keyframes Reveal {
  from {
    opacity: 0;
    top: 100px;
  }

  to {
    opacity: 1;
    top: 0px;
  }
}

@keyframes Hide {
  from {
    opacity: 1;
    top: 0px;
  }

  to {
    display: none;
    opacity: 0;
    top: -100px;
  }
}

@keyframes Float {
  from {
    top: 0px;
  }

  to {
    top: 50px;
  }
}

@media screen and (orientation: portrait) and (max-width: 480px) {
  h2 {
    font-size: 150%;
  }

  .intro {
    margin: auto;
    width: 90%;
    top: 25%;
  }

  .modal .intro {
    top: 0;
  }

  nav {
    top: 6px;
    height: 30px;
  }

  nav button {
    padding: 6px 2px;
  }

  .modal {
    height: inherit !important;
    z-index: 200;
  }

  .container {
    height: 70vh;
    align-items: flex-start;
    text-align: center;

    justify-content: center;
  }

  .start {
    display: block;
  }
}

/* Loading Screen */

.loadingscreen {
  height: 100%;
  width: 100%;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingscreen .icons {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-content: space-between;
  /* padding: 24px 0; */
  /* width: 540px; */
  gap: 12px;
}

.icon {
  opacity: 0;
  animation: Reveal 0.5s linear 0s forwards;
}

.icon:nth-of-type(2) {
  animation-delay: 0.5s !important;
}
.icon:nth-of-type(3) {
  animation-delay: 1s !important;
}
.icon:nth-of-type(4) {
  animation-delay: 1.5s !important;
}
.icon:nth-of-type(5) {
  animation-delay: 2s !important;
}
.icon:nth-of-type(6) {
  animation-delay: 2.5s !important;
}
.icon:nth-of-type(7) {
  animation-delay: 3s !important;
}
.icon:nth-of-type(8) {
  animation-delay: 3.5s !important;
}
.icon:nth-of-type(9) {
  animation-delay: 4s !important;
}
.icon:nth-of-type(10) {
  animation-delay: 4.5s !important;
}

.loadingscreen_progress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: auto;
}

.loading-progress {
  background-color: #33a03e;
  height: 8px;
}

@media screen and (orientation: portrait) and (max-width: 480px) {
  .loadingscreen .message {
    width: 240px;
  }
}
